import React from "react";
import { CaseStudyTemplate, CaseStudyTemplateProps } from "@src/components/CaseTemplete";
import * as UseCase from "@src/components/partials/districts-2025/UseCaseProvider";
import KensingtonPhoto from "@src/www/districts/_assets/kensington-photo.jpg";
import KensingtonLogoWhite from "@src/www/districts/_assets/kensington-white.png";
import KensingtonIcon from "@src/www/districts/_assets/kensington-icon.png";
import KensingtonHeadshot from "@src/www/districts/_assets/kensington-headshot.jpg";
import getLocale from "@src/utils/getLocale";
import KensingtonSEOImage from "@src/www/districts/_assets/berkey-case-study.png";

const NewKensingtonArnold = ({ pageContext }) => {
  const c = UseCase.useGetCase(3);

  const userLanguage = getLocale(pageContext);
  const noIndex = ["en", "en-us"].includes(userLanguage) ? false : true;

  const caseStudyData: CaseStudyTemplateProps = {
    id: "new-kensington-arnold",
    seoImage: KensingtonSEOImage,
    seoDescription:
      "Learn how New Kensington-Arnold decreased suspensions by 97% after switching over to ClassDojo as their new communication platform. ClassDojo is the #1 communication app chosen by K-12 teachers.",
    hero: {
      image: KensingtonPhoto,
      title: c.title,
      logo: KensingtonLogoWhite,
    },
    person: {
      image: KensingtonHeadshot,
      name: c.quote_person_name,
      title: c.quote_person_title,
      quote: c.quote,
    },
    content: c.content,
    summary: [
      {
        text: c.stat_1_text,
        value: c.stat_1_value,
      },
      {
        text: c.stat_2_text,
        value: c.stat_2_value,
        icon: KensingtonIcon,
      },
      {
        text: c.stat_3_text,
        value: c.stat_3_value,
      },
    ],
  };

  return <CaseStudyTemplate {...caseStudyData} />;
};

export default UseCase.withProvider(NewKensingtonArnold);
